<template>
  <path id="Tracé_587" data-name="Tracé 587" d="M4.112,64A4.211,4.211,0,0,1,0,59.7V12.722a4.211,4.211,0,0,1,4.112-4.3h7.359V3.615A3.62,3.62,0,0,1,15.088,0H53.419a3.619,3.619,0,0,1,3.617,3.615v26.2a3.618,3.618,0,0,1-3.617,3.614H32.649V59.7A4.213,4.213,0,0,1,28.537,64ZM1.939,59.7a2.274,2.274,0,0,0,2.174,2.358H28.537A2.275,2.275,0,0,0,30.71,59.7V55.272H1.939Zm28.771-6.37v-19.9H26.367L19.9,41.33a.966.966,0,0,1-.751.356.984.984,0,0,1-.325-.057.97.97,0,0,1-.644-.914V33.433H15.088a3.619,3.619,0,0,1-3.616-3.614V17.933H1.939v35.4ZM13.412,3.615v26.2a1.676,1.676,0,0,0,1.676,1.674h4.061a.969.969,0,0,1,.969.971V38l5.042-6.152a.976.976,0,0,1,.75-.355H53.419A1.678,1.678,0,0,0,55.1,29.819V3.615A1.679,1.679,0,0,0,53.419,1.94H15.088A1.677,1.677,0,0,0,13.412,3.615Zm-1.94,12.378V10.365H4.112a2.273,2.273,0,0,0-2.174,2.356v3.271Zm3,42.575a1.838,1.838,0,1,1,1.84,1.813A1.829,1.829,0,0,1,14.475,58.568Zm1.738,0a.119.119,0,0,0,.1.128v-.256Z" transform="translate(0.5 0.5)" stroke="rgba(0,0,0,0)" stroke-width="1"/>
</template>

<script>
export default {
name: "IconSms"
}
</script>

<style scoped>

</style>